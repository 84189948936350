import { axiosInstance } from "../helper/axios.js";
import backend from "../router/backend";

export default {
  namespaced: true,

  state: {
    courierSettings: {
      travelModes: []
    }
  },

  mutations: {
    SET_TRAVEL_MODES(state, travelModes) {
      state.courierSettings.travelModes = travelModes;
    }
  },

  actions: {
    async fetchTravelModes({ commit, state }) {
      // Eğer store'da veri varsa, yeni request atmadan onu döndür
      if (state.courierSettings.travelModes.length > 0) {
        return state.courierSettings.travelModes;
      }

      // Store boşsa, API'dan çek
      try {
        const response = await axiosInstance.get(backend.COURIER.SETTINGS.TRAVEL_MODES);
        commit('SET_TRAVEL_MODES', response.data);
      } catch (error) {
        console.error('Error fetching travel modes:', error);
        throw error;
      }
    }
  },

  getters: {
    getTravelModes: state => state.courierSettings.travelModes,

    getTravelModeByType: state => type => {
      const mode = state.courierSettings.travelModes.find(
        mode => mode.value === type
      );
      return mode || null;
    }
  }
};
